<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          novalidate
          @submit.prevent='onSubmit'
          v-else
        >
          <b-row class='align-items-end'>

            <b-col
              cols='12'
              md='6'
              v-if='usages.length'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='usage_id'
                :name="$t('usage_id')"
                rules='required'
              >
                <b-form-group
                  :label="$t('usage_id')"
                >
                  <v-select
                    v-model='form.usage_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='usages'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                    @input="inputChange('usage_id')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='privacy_type'
                :name="$t('privacy_type')"
                rules='required'
              >
                <b-form-group
                  :label="$t('privacy_type')"
                >
                  <v-select
                    v-model='form.privacy_type'
                    :dir='$store.state.appConfig.layout.direction'
                    label='label'
                    :options='privacy_list'
                    :reduce='item => item.value'
                    :placeholder="$t('select')"
                    disabled
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='the_sub_type_of_ad'
                :name="$t('the_sub_type_of_ad')"
                rules='required'
              >
                <b-form-group
                  :label="$t('the_sub_type_of_ad')"
                >
                  <v-select
                    v-model='form.the_sub_type_of_ad'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='the_sub_type_of_ads'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
              md='6'
              v-if='types.length'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='type_id'
                :name="$t('type_id')"
                rules='required'
              >
                <b-form-group
                  :label="$t('type_id')"
                >
                  <v-select
                    v-model='form.type_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='types'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                    @input="inputChange('sub_type_id')"

                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <!--            <b-col-->
            <!--              cols='12'-->
            <!--              md='6'-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                v-slot='{ errors }'-->
            <!--                vid='type'-->
            <!--                :name="$t('labels.type')"-->
            <!--                rules='required'-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  :label="$t('labels.type')"-->
            <!--                >-->
            <!--                  <v-select-->
            <!--                    v-model='form.type'-->
            <!--                    :dir='$store.state.appConfig.layout.direction'-->
            <!--                    label='name'-->
            <!--                    :options='types_list'-->
            <!--                    :reduce='item => item.id'-->
            <!--                    :placeholder="$t('select')"-->

            <!--                  >-->
            <!--                    <template #no-options>-->
            <!--                      {{ $t('No options') }}-->
            <!--                    </template>-->
            <!--                  </v-select>-->
            <!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->

            <b-col
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='region_id'
                :name="$t('region')"
                rules='required'
              >
                <b-form-group
                  :label="$t('region')"
                >
                  <v-select
                    v-model='form.region_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='regions'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"

                    @input="inputChange('region_id')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              cols='12'
              md='6'
              v-if='cities.length'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='city_id'
                :name="$t('city')"
                rules='required'
              >
                <b-form-group
                  :label="$t('city')"
                >
                  <v-select
                    v-model='form.city_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='cities'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"

                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('instrument_number')"
                vid='instrument_number'
                :rules='{
                         required:true,
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('instrument_number') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.instrument_number'
                    :placeholder="$t('instrument_number')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('land_number')"
                vid='land_number'
                :rules='{
                          required:true,
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('land_number') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.land_number'
                    :placeholder="$t('land_number')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='status'
                :name="$t('status')"
                rules='required'
              >
                <b-form-group
                  :label="$t('status')"
                >
                  <v-select
                    v-model='form.status'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='status_list'
                    :reduce='item => item.id'
                    :placeholder="$t('status')"
                  >
                    <template #no-options>{{ $t('No options') }}</template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [formMixin],
  computed: {
    toRedirectResource() {
      if (this.$route.name === 'edit-real-estate-leave-on-us-private' || this.$route.name === 'add-real-estate-leave-on-us-private') {
        return 'real-estate-leave-on-us-private'
      }

      return 'real-estate-leave-on-us'

    },
  },
  data() {
    return {
      websiteUrl: websiteMainUrl(),
      usages: [],
      types: [],
      cities: [],
      regions: [],
      the_sub_type_of_ads: [],
      privacy_list: [
        {
          label: this.$t('public'),
          value: 0,
        },
        {
          label: this.$t('private'),
          value: 1,
        },
      ],

      status_list: [
        {
          name: this.$t('leave_it_on_us_status.pending'),
          id: 1,
        },
        {
          name: this.$t('leave_it_on_us_status.created'),
          id: 2,
        },
      ],
      types_list: [
        {
          name: this.$t('leave_it_on_us_request.marketing-request'),
          id: 1,
        },
        {
          name: this.$t('leave_it_on_us_request.un-authorized-ad'),
          id: 2,
        },
      ],
      form: {
        usage_id: null,
        city_id: null,
        privacy_type: null,

        region_id: null,
        the_sub_type_of_ad: null,
        instrument_number: null,
        type_id: null,
        type: null,
        land_number: null,
        status: 1,
      },

      isDataLoaded: false,
    }
  },

  created() {

    if (this.$route.name === 'edit-real-estate-leave-on-us-private' || this.$route.name === 'add-real-estate-leave-on-us-private') {
      this.form.privacy_type = 1
    }

    (async () => {
      await this.getLists()
    })();

    (async () => {
      await this.getUsages()
    })();

    (async () => {
      await this.getRegion()
    })()

    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            usage_id: data.usage_id,
            region_id: data.region_id,
            city_id: data.city_id,
            instrument_number: data.instrument_number,
            type: data.type,
            the_sub_type_of_ad: data.the_sub_type_of_ad,
            type_id: data.type_id,
            land_number: data.land_number,
            status: data.status,
            privacy_type: data.privacy_type,

          };
          (async () => {
            await this.getCities()
            await this.getUsages()
            await this.getTypesUsage()
          })()

          setTimeout(() => {
            this.isDataLoaded = true
          }, 2800)
        })
    },
    async getLists() {
      await this.axios.get(`/model-options/type-category?model=9`)
        .then(res => {
          this.the_sub_type_of_ads = res.data.data
        })
    },
    async getRegion() {
      await this.axios.get(`/model-options/region`)
        .then(res => {
          this.regions = res.data.data
        })
    },

    async getUsages() {
      await this.axios.get(`/model-options/usage`)
        .then(res => {
          this.usages = res.data.data
        })
    },

    async getTypesUsage() {
      await this.axios.get(`/model-options/types?usage_id=${this.form.usage_id}`)
        .then(res => {
          this.types = res.data.data
        })
    },

    inputChange(type) {

      if (type == 'region_id') {
        this.cities = []
        this.form.city_id = null;

        (async () => {
          await this.getCities()
        })()
      }

      if (type == 'usage_id') {
        this.types = []

        this.form.sub_type_id = null

        this.getTypesUsage()
      }
    },
    getCities() {
      if (this.form.city_id === null) {
        this.cities = []
      }

      this.axios.get(`/model-options/city?region_id=${this.form.region_id}`)
        .then(res => {
          this.cities = res.data.data
          return 1
        })
    },

  },

}
</script>
